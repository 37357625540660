import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import IntroCopy from "../../components/IntroCopy";
import FullWidthInfo from "../../components/FullWidthInfo";
import ImageGallery from "../../components/ImageGallery";
import ImageLockup from "../../components/ImageLockup";

const TilingAndSlatingPage = ({ data }) => (
  <Layout>
    <main>
      <SEO title="Portfolio - Tiling and Slating" />
      <IntroCopy
        headline="Tiling and Slating"
        body="Our tradesmen are highly skilled tillers and slaters who work with all types of tilling including, Handmade clay peg tiles, clay and concrete plain tiles and Interlocking pan tiles. We also work with a variety of Slate including Welsh, Spanish, fibre cement slates."
        topPadding
      />
      <ImageGallery
        images={data.images.nodes.filter(image => image.name === "1")}
      />
      <FullWidthInfo
        headline="Plain Tiles"
        body="Tiles come in a variety of styles from machine made Concrete to Clay peg tiles, each giving a very different appearance. Our staff understand the different nature of each tile and how to combine traditional techniques and modern materials to create the desired appearance.
        
        On the left is a large strip and re tile project on a period property. The original clay peg tiles, ridges, finials, valleys and bonnets were stripped off and sound tiles reused. The utmost care was taken to carry out a job sympathetic to the style of the property, to what we think is stunning results.
        
        On the right is a new concrete tiled verge, which gives a fair more even appearance."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "2")}
        right={data.images.nodes.filter(image => image.name === "3")}
      />
      <FullWidthInfo
        headline="Slating"
        body="A variety of materials can be used to suit the customers preference and budget. From New Spanish to Reclaimed Slates. Slates have always been a popular roofing option not only due to its appearance but because its 100% Natural, relatively lightweight and resistance to degradation."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "4")}
        right={data.images.nodes.filter(image => image.name === "5")}
      />
      <FullWidthInfo
        headline="Interlocking Pantiles"
        body="Pantiles are becoming an increasingly popular roofing option, with the benefits of lower cost and lightweight per square meter. The tiles come in a multitude of profiles and are an excellent option on low pitch roofs, with certain profiles going as low as 15 degrees. More commonly available in concrete but also available in clay. 
        
        Here are two examples of our works with Pantiles."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "6")}
        right={data.images.nodes.filter(image => image.name === "7")}
      />
    </main>
  </Layout>
);

export const query = graphql`
  query TilingImages {
    images: allFile(
      filter: { relativeDirectory: { eq: "portfolio/tiling-and-slating" } }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default TilingAndSlatingPage;
