import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledImageLockup = styled.div`
  min-height: 350px;
  background-color: #c1c1c1;

  .gatsby-image-wrapper,
  img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  div {
    position: relative;
    height: 350px;
    width: 100%;

    @media only screen and (min-width: 740px) {
      width: 50%;
      height: 480px;
    }

    @media only screen and (min-width: 920px) {
      height: 600px;
    }

    span {
      background: rgba(0, 0, 0, 0.8);
      width: 80px;
      color: #fff;
      font-family: "Lato";
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      padding: 10px;
      text-transform: uppercase;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }

  @media only screen and (min-width: 740px) {
    min-height: 480px;
    display: flex;
  }

  @media only screen and (min-width: 920px) {
    min-height: 600px;
  }
`;

const ImageLockup = ({ left, right, withLabels }) => {
  return (
    <StyledImageLockup>
      {left.map(image => (
        <div>
          <Img fluid={image.childImageSharp.fluid} />
          {withLabels && <span>Before</span>}
        </div>
      ))}
      {right.map(image => (
        <div>
          <Img fluid={image.childImageSharp.fluid} />
          {withLabels && <span>After</span>}
        </div>
      ))}
    </StyledImageLockup>
  );
};

ImageLockup.propTypes = {
  withLabels: PropTypes.bool,
};

ImageLockup.defaultProps = {
  withLabels: false,
};

export default ImageLockup;
